import { Link } from "gatsby"
import React from "react"

const Footer = () => (
  <footer className="footer_area p_120">
    <div className="container">
      <div className="row footer_inner">
        <div className="col-lg-3 col-sm-6">
          <aside className="f_widget ab_widget">
            <div className="f_title">
              <h3>Quick Links</h3>
            </div>
            <ul className="footer-links">
              <li>
                <Link to="/about-us">About</Link>
              </li>
              <li>
                <Link to="/enterprise-iot-assistance">Solutions</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>
                <Link to="/products">Products</Link>
              </li>
              <li>
                <Link to="/consulting">Consulting</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </aside>
        </div>
        <div className="col-lg-6 col-sm-6">
          <aside className="f_widget news_widget">
            <div className="f_title">
              <h3>Newsletter</h3>
            </div>
            <p>Subscribe to our news updates.</p>
            <div id="mc_embed_signup">
              <form
                target="_blank"
                action="//newsletter?u=kjcxh9ewd7wheo8o8c7shf87e&amp;id=68686rwe6r"
                method="get"
                className="subscribe_form relative"
              >
                <div className="input-group d-flex flex-row">
                  <input
                    name="EMAIL"
                    placeholder="Enter email address"
                    onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'Email Address '"
                    required=""
                    type="email"
                  />
                  <button className="btn sub-btn">
                    <span className="lnr lnr-envelope"></span>
                  </button>
                </div>
                <div className="mt-10 info"></div>
              </form>
            </div>
          </aside>
        </div>
        <div className="col-lg-3">
          <aside className="f_widget social_widget">
            <div className="f_title">
              <h3>Follow Us</h3>
            </div>
            <p>Let us be social</p>
            <ul className="list">
              <li>
                <Link href="#">
                  <i className="fa fa-facebook"></i>
                </Link>
              </li>
              <li>
                <Link to="//twitter.com/famlinx" target="_blank">
                  <i className="fa fa-twitter"></i>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <i className="fa fa-instagram"></i>
                </Link>
              </li>
              <li>
                <Link to="//www.linkedin.com/company/ngnapps/" target="_blank">
                  <i className="fa fa-linkedin"></i>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <i className="fa fa-youtube"></i>
                </Link>
              </li>
            </ul>
          </aside>
        </div>
        <div className="col-12">
          <aside className="f_widget copy_right_text">
            <p>
              Copyright&copy; NGNAPPS {new Date().getFullYear()} , All rights
              reserved | <Link to="#">Terms of use</Link> |{" "}
              <Link to="#">Privacy Policy</Link>
            </p>
          </aside>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
