import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import Collapse from "react-bootstrap/Collapse"

import Logo from "../images/logo.png"

const HEADER_LIMIT = 60

const Header = ({ siteTitle }) => {
  const [open, setOpen] = useState(false)
  const [headerY, setHeaderY] = useState(0)
  const headerScroll = e => {
    setHeaderY(window.pageYOffset)
  }
  useEffect(() => {
    setHeaderY(window.pageYOffset)
    window.addEventListener("scroll", headerScroll)
    return () => {
      window.removeEventListener("scroll", headerScroll)
    }
  }, [])
  return (
    <header
      className={
        headerY < HEADER_LIMIT ? "header_area" : "header_area navbar_fixed"
      }
    >
      <div className="main_menu" id="mainNav">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container box_1620">
            <Link
              className="navbar-brand logo_h"
              title="ngLINX - Smartly Connected"
              to="/"
            >
              <img src={Logo} alt="ngLINX" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-label="Toggle navigation"
              onClick={() => setOpen(!open)}
              aria-expanded={open}
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <Collapse in={open}>
              <div
                className="collapse navbar-collapse offset"
                id="navbarSupportedContent"
              >
                <ul className="nav navbar-nav menu_nav ml-auto">
                  <li className="nav-item">
                    <Link className="nav-link" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" href="/about-us">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item submenu dropdown">
                    <SolutionsMenu />
                  </li>
                  {/* <li className="nav-item submenu dropdown">
                    <ServicesMenu />
                  </li>
                  <li className="nav-item submenu dropdown">
                    <ProductsMenu />
                  </li> */}
                  <li className="nav-item">
                    <Link className="nav-link" href="/products">
                      Products
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" href="/services">
                      Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" href="/consulting">
                      Consulting
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contact-us">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </div>
        </nav>
      </div>
    </header>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

const SolutionsMenu = () => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Link
        to="#"
        className={
          !open ? "nav-link dropdown-toggle" : "nav-link dropdown-toggle show"
        }
        data-toggle="dropdown"
        role="button"
        aria-haspopup="true"
        aria-expanded={open}
        onClick={() => setOpen(!open)}
      >
        Solutions
      </Link>
      <ul className={!open ? "dropdown-menu" : "dropdown-menu show"}>
        <li className="nav-item">
          <Link className="nav-link" to="/enterprise-iot-assistance">
            Enterprise IOT Assistance
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/private-lte">
            Private LTE
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/virtual-evolved-packet-core">
            vEPC
          </Link>
        </li>
      </ul>
    </>
  )
}

// const ServicesMenu = () => {
//   const [open, setOpen] = useState(false)
//   return (
//     <>
//       <Link
//         to="#"
//         className={
//           !open ? "nav-link dropdown-toggle" : "nav-link dropdown-toggle show"
//         }
//         data-toggle="dropdown"
//         role="button"
//         aria-haspopup="true"
//         aria-expanded={open}
//         onClick={() => setOpen(!open)}
//       >
//         Services
//       </Link>
//       <ul className={!open ? "dropdown-menu" : "dropdown-menu show"}>
//         <li className="nav-item">
//           <Link className="nav-link" to="/ir-roaming">
//             IR-Roaming
//           </Link>
//         </li>
//         <li className="nav-item">
//           <Link className="nav-link" to="/iot-negotiations">
//             IOT Negotiations
//           </Link>
//         </li>
//         <li className="nav-item">
//           <Link className="nav-link" to="/ireg-or-tadig">
//             IREG/TADIG
//           </Link>
//         </li>
//         <li className="nav-item">
//           <Link className="nav-link" to="/automation">
//             Automation
//           </Link>
//         </li>
//         <li className="nav-item">
//           <Link className="nav-link" to="/quality-assurance">
//             Quality Assurance
//           </Link>
//         </li>
//         <li className="nav-item">
//           <Link className="nav-link" to="/reporting">
//             Reporting
//           </Link>
//         </li>
//       </ul>
//     </>
//   )
// }

// const ProductsMenu = () => {
//   const [open, setOpen] = useState(false)
//   return (
//     <>
//       <Link
//         to="#"
//         className={
//           !open ? "nav-link dropdown-toggle" : "nav-link dropdown-toggle show"
//         }
//         data-toggle="dropdown"
//         role="button"
//         aria-haspopup="true"
//         aria-expanded={open}
//         onClick={() => setOpen(!open)}
//       >
//         Products
//       </Link>
//       <ul className={!open ? "dropdown-menu" : "dropdown-menu show"}>
//         <li className="nav-item">
//           <Link className="nav-link" to="/vhss">
//             vHSS
//           </Link>
//         </li>
//         <li className="nav-item">
//           <Link className="nav-link" to="/vdiameter">
//             vDIAMETER
//           </Link>
//         </li>
//         <li className="nav-item">
//           <Link className="nav-link" to="/vpgw">
//             vPGW
//           </Link>
//         </li>
//         <li className="nav-item">
//           <Link className="nav-link" to="/vmme">
//             vMME
//           </Link>
//         </li>
//         <li className="nav-item">
//           <Link className="nav-link" to="/vsgw">
//             vSGW
//           </Link>
//         </li>
//       </ul>
//     </>
//   )
// }
